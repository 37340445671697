<template>
  <div>
    <!-- table -->
    <vue-good-table
      ref="sitesTable"
      :columns="columns"
      :rows="sites"
      :rtl="direction"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'status', type: 'asc'}
      }"
      :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
      style-class="vgt-table striped"
    >

      <template slot="column-filter">
        <span style="display:none;" />
      </template>
      <!-- Slot: Table Column -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span
          v-if="props.column.label ==='Status'"
          class="text-nowrap"
        >
          {{ $t('campaigns.status') }}
        </span>
        <span
          v-else-if="props.column.label ==='previous_month'"
          class="text-nowrap"
        >
          {{ $t('reportsTableHeaders.sites.previous_month') }}
        </span>
        <span
          v-else-if="props.column.label ==='current_month'"
          class="text-nowrap"
        >
          {{ $t('reportsTableHeaders.sites.current_month') }}
        </span>
        <span
          v-else-if="props.column.label ==='Name'"
          class="text-nowrap"
        >
          {{ $t('sites.sitetitle') }}
        </span>
        <span
          v-else-if="props.column.label ==='Category'"
          class="text-nowrap"
        >
          {{ $t('sites.category') }}
        </span>
        <span
          v-else-if="props.column.label ==='Country'"
          class="text-nowrap"
        >
          {{ $t('stats.country') }}
        </span>
        <span
          v-else-if="props.column.label ==='Impressions'"
          class="text-nowrap"
        >
          {{ $t('stats.impressions') }}
        </span>
        <span
          v-else-if="props.column.label ==='Action'"
          class="text-nowrap"
        >
          {{ $t('Action') }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span
          v-if="props.column.field === 'status'"
          class="text-nowrap"
        >
          <b-form-checkbox
            :checked="props.row.status === 'active' ? true : false"
            name="check-button"
            switch
            size="lg"
            @change="changeStatus(props.row._id)"
          />
        </span>

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'title'"
          class="text-nowrap"
        >
          <router-link :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/'"><strong>{{ trimEllip(props.row.title, 40) }}</strong></router-link>
          <b-badge
            v-if="props.row.is_moderate"
            class="ml-25"
            :variant="statusVariant('Resigned')"
          >
            {{ $t('campaigns.moderation') }}
          </b-badge>
          <template v-if="authUserData.role.includes('admin') && props.row.tags">
            <b-badge
              v-for="(tag, idx) in props.row.tags.split(',')"
              :key="idx"
              class="tag-badge ml-25"
              :variant="statusVariant('Tags')"
            >
              {{ tag.trim() }}
            </b-badge>
          </template>
          <br>
          <small>{{ props.row.domain }}</small>
        </span>

        <span
          v-if="props.column.label ==='previous_month'"
          class="text-nowrap"
        >
          <small>{{ currency(account.currency, Object.values(props.row.earnings[0])[0]) }}</small>
        </span>
        <span
          v-if="props.column.label ==='current_month'"
          class="text-nowrap"
        >
          <small>{{ currency(account.currency, Object.values(props.row.earnings[1])[0]) }}</small>
        </span>

        <!-- Column: Country -->
        <span
          v-if="props.column.field === 'shows'"
          class="text-nowrap"
        >
          <small>{{ props.row.shows }}</small>
        </span>

        <!-- Column: Category -->
        <span
          v-if="props.column.field === 'category'"
          class="text-nowrap"
        >
          <small>{{ toCapitalize(props.row.category) }}</small>
        </span>

        <!-- Column: Country -->
        <span
          v-if="props.column.field === 'country'"
          class="text-nowrap"
        >
          <small>{{ toCapitalize(props.row.country) }}</small>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-center">
            <b-button
              v-b-tooltip.hover.top="$t('Statistics')"
              :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id"
              variant="flat"
              class="btn-action"
            >
              <feather-icon
                icon="BarChart2Icon"
                size="16"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('form.change')"
              :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/edit/'"
              variant="flat"
              class="btn-action"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('page.features.widgets')"
              :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/widgets/'"
              variant="flat"
              class="btn-action"
            >
              <feather-icon
                icon="GridIcon"
                size="16"
              />
            </b-button>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              right
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/promotions/'"
              >
                <feather-icon
                  icon="TagIcon"
                  class="mr-50"
                />
                <span>{{ $t('Promotions') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/clicks/'"
              >
                <feather-icon
                  icon="MousePointerIcon"
                  class="mr-50"
                />
                <span>{{ $t('Clicks sources') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/sites/'+ props.row._id + '/toparticles/'"
              >
                <feather-icon
                  icon="ActivityIcon"
                  class="mr-50"
                />
                <span>{{ $t('TOP articles') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        {{ setTotals(props.total) }}
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
              v-model="localPageLength"
              :options="['5','10','20','40']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="localPageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  BDropdown,
  BDropdownItem,
  BButton,
  VBTooltip,
  BBadge,
  BFormCheckbox,
  BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import currencyFormatter from '@/utils/currency-formatter'
import { trimEllip, toCapitalize } from '@/utils/helpers'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BDropdown,
    BDropdownItem,
    BButton,
    BBadge,
    BFormCheckbox,
    BFormSelect,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    sites: {
      type: Array,
      require: true,
      default: () => [],
    },
    authUserData: {
      type: Object,
      require: true,
      default: () => {},
    },
    account: {
      type: Object,
      require: true,
      default: () => {},
    },
    pageLength: {
      type: Number,
      require: true,
      default: 10,
    },
  },
  data() {
    return {
      currency: currencyFormatter,
      toCapitalize,
      trimEllip,
      totals: 0,
      localPageLength: this.pageLength,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          width: '9%',
          type: 'boolen',
        },
        {
          label: 'previous_month',
          field: 'previous_month',
        },
        {
          label: 'current_month',
          field: 'current_month',
        },
        {
          label: 'Impressions',
          field: 'shows',
          type: 'number',
          width: '10%',
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Domain',
          field: 'domain',
          hidden: true,
        },
        {
          label: 'tags',
          field: 'tags',
          hidden: true,
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Category',
          field: 'category',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          width: '10%',
          thClass: 'd-none d-lg-table-cell',
          tdClass: 'd-none d-lg-table-cell',
        },
        {
          label: 'Country',
          field: 'country',
          globalSearchDisabled: true,
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          width: '10%',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          width: '5%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        Tags          : 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    localPageLength(newVal) {
      this.$emit('update:pageLength', parseInt(newVal, 10))
    },
  },
  methods: {
    async changeStatus(id) {
      const responseData = await useJwt.getSite(id)
      const site = responseData.data.site || []
      site.status = site.status === 'inactive' ? 'active' : 'inactive'
      if (site.status === 'active') {
        this.$swal({
          icon: 'success',
          title: 'Activation!',
          text: 'Successfully activated.',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        useJwt.setSite(id, { site })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            site.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setSite(id, { site })
        })
      }
    },
    setTotals(count) {
      this.totals = count
    },
  },
}
</script>
