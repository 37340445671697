<template>
  <div>
    <!-- table -->
    <vue-good-table
      ref="campaignsTable"
      :columns="filtredColumns"
      :rows="campaigns"
      :rtl="direction"
      :sort-options="{
        enabled: true,
        initialSortBy: {field: 'status', type: 'asc'}
      }"
      :pagination-options="{
        enabled: false,
        perPage:pageLength
      }"
      style-class="vgt-table striped"
    >
      <template slot="column-filter">
        <span style="display:none;" />
      </template>
      <!-- Slot: Table Column -->
      <template
        slot="table-column"
        slot-scope="props"
      >
        <template slot="column-filter">
          <span style="display:none;" />
        </template>
        <span
          v-if="props.column.label ==='Status'"
          class="text-nowrap"
        >
          {{ $t('campaigns.status') }}
        </span>
        <span
          v-else-if="props.column.label ==='Name'"
          class="text-nowrap"
        >
          {{ $t('campaigns.campaigntitle') }}
        </span>
        <span
          v-else-if="props.column.label ==='Type'"
          class="text-nowrap"
        >
          {{ $t('campaigns.type') }}
        </span>
        <span
          v-else-if="props.column.label ==='Country'"
          class="text-nowrap"
        >
          {{ $t('stats.country') }}
        </span>
        <span
          v-else-if="props.column.label ==='Balance'"
          class="text-nowrap"
        >
          {{ $t('profile.balance') }}
        </span>
        <span
          v-else-if="props.column.label ==='Action'"
          class="text-nowrap"
        >
          {{ $t('Action') }}
        </span>
        <span v-else>
          {{ props.column.label }}
        </span>
      </template>

      <!-- Slot: Table Row -->
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Status -->
        <span
          v-if="props.column.field === 'status'"
          class="text-nowrap"
        >
          <b-form-checkbox
            :checked="props.row.status === 'active' ? true : false"
            name="check-button"
            switch
            size="lg"
            @change="changeStatus(props.row)"
          />
        </span>

        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'title'"
          class="text-nowrap"
        >
          <router-link :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/'"><span>{{ trimEllip(props.row.title, 40) }}</span></router-link>
          <b-badge :variant="statusVariant(props.row.status)">
            {{ $t('campaigns.' + props.row.status) }}
          </b-badge>
          <b-badge
            v-if="props.row.is_moderate"
            class="ml-25"
            :variant="statusVariant('Resigned')"
          >
            {{ $t('campaigns.moderation') }}
          </b-badge>
          <b-badge
            v-if="props.row.startdate && new Date(props.row.enddate).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)"
            class="ml-25"
            :variant="statusVariant('Resigned')"
          >
            {{ $t('campaigns.checkdate') }}
          </b-badge>
        </span>

        <!-- Column: Type -->
        <span
          v-if="props.column.field === 'type'"
          class="text-nowrap"
        >
          <small>{{ $t(`selects.adstype.${props.row.type}`) }}</small>
        </span>

        <!-- Column: Country -->
        <span
          v-if="props.column.field === 'country_name'"
          class="text-nowrap"
        >
          <span>
            <small>{{ props.row.country_name ? toCapitalize(props.row.country_name) : '' }}</small>
          </span>
        </span>

        <!-- Column: Balance -->
        <span
          v-if="props.column.field === 'balance'"
          class="text-nowrap"
        >
          <span>
            <small>{{ currency(props.row.currency, props.row.balance) }}</small>
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex justify-content-center">
            <b-button
              v-b-tooltip.hover.top="$t('Statistics')"
              :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id"
              variant="flat"
              class="btn-action"
            >
              <feather-icon
                icon="BarChart2Icon"
                size="16"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.top="$t('form.change')"
              :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/edit/'"
              variant="flat"
              class="btn-action"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
              />
            </b-button>

            <template v-if="props.row.type !== 'criteo' && props.row.type !== 'cpcfeed' && props.row.type !== 'adsense'">
              <b-button
                v-b-tooltip.hover.top="$t('campaigns.banners')"
                :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/creatives/table'"
                variant="flat"
                class="btn-action"
              >
                <feather-icon
                  icon="ImageIcon"
                  size="16"
                />
              </b-button>
            </template>

            <b-dropdown
              variant="link"
              right
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>

              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/clicks'"
              >
                <feather-icon
                  icon="MousePointerIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span>{{ $t('Clicks sources') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/pixelclicks'"
              >
                <feather-icon
                  icon="ActivityIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span>{{ $t('page.features.pixelclick') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/geoclicks'"
              >
                <feather-icon
                  icon="GlobeIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span>{{ $t('page.features.geoclick') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="'/users/'+ props.row.user_id + '/campaigns/'+ props.row._id + '/payments'"
              >
                <feather-icon
                  icon="CreditCardIcon"
                  size="16"
                  class="align-middle mr-50"
                />
                <span>{{ $t('profile.balance') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-clipboard:copy="getPixelCode(props.row._id)"
                v-clipboard:success="onCopyPixelCode"
                v-clipboard:error="onErrorCopyPixelCode"
              >
                <feather-icon
                  icon="CodeIcon"
                  class="mr-50"
                />
                <span>{{ $t('campaigns.copypixel') }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="authUserData.role.includes('admin')"
                @click="copyCampaign(props.row)"
              >
                <feather-icon
                  icon="CopyIcon"
                  class="mr-50"
                />
                <span>{{ $t('form.copy') }}</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                v-if="authUserData.role.includes('admin')"
                variant="danger"
                @click="deleteCampaign(props.row._id, props.row.title)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>{{ $t('form.delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('message.pagelength') }}
            </span>
            <b-form-select
              v-model="localPageLength"
              :options="['5','10','20','40']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap">  {{ $t('message.of') }} {{ props.total }} {{ $t('message.pageText2') }} </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="localPageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              {{ totals = props.total }}
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import options from '@/libs/options'
import currencyFormatter from '@/utils/currency-formatter'
import {
  BBadge, BPagination, BFormCheckbox, BFormSelect, BDropdown, BDropdownItem, BDropdownDivider, BButton, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { trimEllip, toCapitalize } from '@/utils/helpers'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VueGoodTable,
    BBadge,
    BPagination,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    campaigns: {
      type: Array,
      require: true,
      default: () => [],
    },
    authUserData: {
      type: Object,
      require: true,
      default: () => {},
    },
    account: {
      type: Object,
      require: true,
      default: () => {},
    },
    pageLength: {
      type: Number,
      require: true,
      default: 10,
    },
  },
  data() {
    return {
      loading: true,
      currency: currencyFormatter,
      dir: false,
      toCapitalize,
      trimEllip,
      columns: [
        {
          label: 'Name',
          field: 'title',
        },
        {
          label: 'Status',
          field: 'status',
          width: '9%',
          type: 'boolen',
        },
        {
          label: '_id',
          field: '_id',
          hidden: true,
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          hidden: false,
          width: '15%',
        },
        {
          label: 'Country',
          field: 'country_name',
          filterOptions: {
            enabled: true,
            filterValue: '',
          },
          width: '15%',
        },
        {
          label: 'Balance',
          field: 'balance',
          type: 'number',
          thClass: 'text-left',
          tdClass: 'text-left',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '5%',
          sortable: false,
        },
      ],
      rows: [],
      totals: 0,
      localPageLength: this.pageLength,
      ...options,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        active       : 'light-success',
        inactive     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    filtredColumns() {
      if (this.authUserData.is_agency_member) {
        return this.columns.filter(f => f.field !== 'balance')
      }
      return this.columns
    },
  },
  watch: {
    localPageLength(newVal) {
      this.$emit('update:pageLength', parseInt(newVal, 10))
    },
  },
  methods: {
    async changeStatus(campaign) {
      campaign.status = campaign.status === 'inactive' ? 'active' : 'inactive'
      campaign.startdate = campaign.startdate !== null && campaign.startdate !== '' ? new Date(campaign.startdate).toISOString() : campaign.startdate
      campaign.enddate = campaign.enddate !== null && campaign.enddate !== '' ? new Date(campaign.enddate).toISOString() : campaign.enddate

      if (campaign.targetings.date.params !== undefined) {
        campaign.targetings.date.params.startdate = new Date(campaign.targetings.date.params.startdate).toISOString()
        campaign.targetings.date.params.enddate = new Date(campaign.targetings.date.params.enddate).toISOString()
      }

      if (campaign.balance <= 0) {
        this.$swal({
          title: 'Please top up balance',
          text: 'Not enough balance to activate',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (campaign.status === 'active') {
        useJwt.setCampaign(campaign._id, { campaign })
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Activation!',
              text: 'Successfully activated.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
          .catch(error => {
            campaign.status = 'inactive'
            if (error.response && error.response.status === 422) {
              if (error.response.data.errors.length) {
                error.response.data.errors.forEach(err => {
                  const field = err.source.pointer.split('/')
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Error',
                      icon: 'AlertTriangleIcon',
                      variant: 'danger',
                      text: `${field[field.length - 1]}: ${err.detail}`,
                    },
                  })
                })
              }
            }
          })
      } else {
        this.$swal({
          title: 'Confirmation',
          text: 'Will be inactive',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$swal({
              icon: 'success',
              title: 'Deactivation!',
              text: 'Successfully deactivated',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else if (result.dismiss === 'cancel') {
            campaign.status = 'active'
            this.$swal({
              title: 'Cancelled',
              text: 'Status remain active',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          useJwt.setCampaign(campaign._id, { campaign })
        })
      }
    },
    async copyCampaign(campaign) {
      const dataResponse = await useJwt.getCampaignBanners({ campaign_id: campaign._id })
      const banners = dataResponse.data.banners || []

      campaign.targetings.date.params.startdate = new Date().toISOString()
      campaign.targetings.date.params.enddate = new Date().toISOString()

      delete campaign._id
      const title = `${campaign.title} (Copy)`
      const newCampaign = {
        ...campaign,
        startdate: null,
        enddate: null,
        dailyclicks: 0,
        title,
      }
      await useJwt.createCampaign({ campaign: newCampaign })
        .then(async response => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.copysuccess'),
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.$emit('fetchAccountCampaigns', this.account._id)
          const newCampaignId = response.data.campaign._id
          this.copyBanners(banners, newCampaignId)
        })
    },
    async copyBanners(banners, newCampaignId) {
      if (banners.length) {
        banners
          .map(b => ({ ...b, campaign_id: newCampaignId }))
          .forEach(async newBanner => {
            await useJwt.uploadBanner(newBanner)
          })
      }
    },
    deleteCampaign(id, name) {
      this.$swal({
        title: `${this.$t('swal.deletetitle')} ${name}?`,
        text: this.$t('swal.campaigndeletemessage'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('form.cancel'),
        confirmButtonText: this.$t('form.delete'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          useJwt.deleteCampaign(id).then(deleted => {
            if (deleted.status === 204) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: this.$t('swal.successfullydeleted'),
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$emit('fetchAccountCampaigns', this.account._id)
            }
          })
        }
      })
    },
    getPixelCode(campaignId) {
      return `<script>!function(e,t,a){var n=t.getElementsByTagName("script")[0],c=t.createElement("script"),i=function(){n.parentNode.insertBefore(c,n)};c.dataset.id="${campaignId}",c.type="text/javascript",c.async=!0,c.src="//phoenix-widget.com/static/js/pixel.js","[object Opera]"==e.opera?t.addEventListener("DOMContentLoaded",i,!1):i()}(window,document);<${''}/script>`
    },
    onCopyPixelCode() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('campaigns.copypixelcopy'),
          icon: 'BellIcon',
        },
      })
    },
    onErrorCopyPixelCode() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('campaigns.copypixelerror'),
          icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
<style>
@media (min-width: 1440px) {
  .vgt-responsive {
    overflow-x: visible !important;
  }
}
</style>
